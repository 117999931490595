import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

export const ContentToggle = ({ children }) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => setIsMobile(window.innerWidth <= 767);

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  const toggleContent = () => {
    setIsActive(!isActive);
  };

  if (!children) {
    return null;
  }

  return (
    <div className="content-toggle-wrapper">
      {isMobile && (
        <span
          className="toggle-button has-text-info is-flex is-align-items-center"
          onClick={toggleContent}
        >
          {t('home.show_business_plan_features')}
          <FontAwesomeIcon
            className="is-flex"
            icon={isActive ? faChevronUp : faChevronDown}
            style={{ marginLeft: 'auto' }}
          />
        </span>
      )}
      <div className={`content-toggle ${isMobile && isActive ? 'active' : ''}`}>
        {children}
      </div>
    </div>
  );
};

ContentToggle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentToggle;
