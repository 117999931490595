import * as React from 'react';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { ContentToggle } from './ContentToggle';

const Pricing = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className="pricing fixed-grid has-1-cols-mobile has-1-cols-tablet has-2-cols-desktop has-4-cols-widescreen">
      {map(data, price => (
        <div
          key={price.plan}
          className="box is-flex is-flex-direction-column m-4 p-5"
          style={{ position: 'relative', minWidth: '240px' }}
        >
          {price.recommended && (
            <span
              className="is-size-7 has-background-success has-text-white-bis m-0 p-1"
              style={{
                position: 'absolute',
                borderRadius: '2px',
                right: '1rem',
              }}
            >
              {t('home.best_to_start')}
            </span>
          )}
          <h2 className="mt-0">{price.plan}</h2>
          <div className="is-flex">
            <span>$</span>
            <h1 className="m-0 is-size-1 has-text-weight-bold">
              {price.price}
            </h1>
            <span className="is-flex is-align-items-end">/mo</span>
          </div>
          <div
            className="is-flex mt-4"
            style={{ borderBottom: '1px solid #ddd' }}
          >
            <p
              className="is-size-5 is-size-5-mobile"
              style={{ maxWidth: '280px' }}
            >
              {price.description}
            </p>
          </div>
          <div
            className="is-flex is-flex-direction-column mt-1"
            style={{ maxWidth: '280px', height: '100%' }}
          >
            <p>{price.inItems}</p>
            <ContentToggle>
              {price.items && (
                <div className="list-wrapper mt-1">
                  <ul
                    className="is-size-7 m-0"
                    style={{ listStyleType: 'none' }}
                  >
                    {map(price.items, item => (
                      <li key={item}>
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="has-text-success mr-1"
                        />
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </ContentToggle>
            <div className="grab-wrapper pt-4" style={{ marginTop: 'auto' }}>
              <button
                className="bttn violet is-fullwidth"
                style={{ width: '100%' }}
              >
                <div className="hover-flip">
                  <span className="first">{price.text}</span>
                  <span className="second">{price.text}</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

Pricing.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      plan: PropTypes.string,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      description: PropTypes.string,
      items: PropTypes.array,
      inItems: PropTypes.string,
      text: PropTypes.string,
      recommended: PropTypes.bool,
    }),
  ),
};

export default Pricing;
